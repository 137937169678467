import { FC, memo, useEffect } from "react";

import { Box, Paper, Typography } from "@mui/material";

import LiveBadge from "../../../../../../common/components/icons/LiveBadge";
import CircularLoading from "../../../../../../common/components/progress/CircularLoading";
import VideoNotFound from "../../../../../../common/components/stream/VideoNotFound";
import useHlsStream, {
  MAX_RETRIES,
} from "../../../../../../common/hooks/useHLSStream";
import LiveStreamPlayer from "./LiveStreamPlayer";

interface HLSStreamComponentProps {
  height?: string;
  setDialogStyles?: boolean;
  videoLoading?: boolean;
  setVideoLoading?: (value: boolean) => void;
  setVideoError?: () => void;
}

const TryingToConnect = ({
  retryCount,
}: {
  retryCount: number;
}): JSX.Element => {
  return (
    <Paper
      variant="outlined"
      square={false}
      elevation={0}
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "378px",
        backgroundColor: "#fbfbfb",
        borderColor: "#e1e6ef",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: "1rem",
        }}
        component="div"
        variant="inputLabel"
      >
        {/* add one to adjust for zero index */}
        Trying to connect... attempt {retryCount + 1} of {MAX_RETRIES}
        <CircularLoading />
      </Typography>
    </Paper>
  );
};

const HLSStreamComponent: FC<HLSStreamComponentProps> = ({
  height,
  setDialogStyles,
  videoLoading,
  setVideoLoading,
  setVideoError,
}): JSX.Element => {
  const { loading, hlsUrl, retryCount, needRetry, retry, setLoading } =
    useHlsStream();

  const handleRetry = (): void => {
    setLoading(true);

    if (setVideoLoading) {
      setVideoLoading(true);
    }

    retry();
  };

  useEffect(() => {
    if (setVideoLoading) {
      const videoNotFound =
        !needRetry && !hlsUrl && !loading && retryCount >= MAX_RETRIES;

      setVideoLoading(loading || videoNotFound || !hlsUrl);

      if (!hlsUrl && retryCount >= MAX_RETRIES && !loading && setVideoError) {
        setVideoError();
      }
    }
  }, [loading, hlsUrl, retryCount, needRetry]);

  if (!needRetry && !hlsUrl && !loading && retryCount < MAX_RETRIES) {
    return <TryingToConnect retryCount={retryCount} />;
  }

  if (loading) {
    return <TryingToConnect retryCount={retryCount} />;
  }

  if (!needRetry && !hlsUrl && !loading && retryCount >= MAX_RETRIES) {
    return <VideoNotFound onRetry={handleRetry} />;
  }

  if (needRetry) {
    return <VideoNotFound onRetry={handleRetry} />;
  }

  return (
    <>
      {!loading && !needRetry && !videoLoading && hlsUrl && (
        <Box
          sx={{
            position: "relative",
            height: setDialogStyles ? "calc(100% - 64px)" : "auto",
          }}
        >
          <LiveBadge
            sx={{
              position: "absolute",
              top: "8px",
              left: "8px",
            }}
          />
        </Box>
      )}

      <Box
        sx={{
          width: videoLoading ? "100%" : "fit-content",
          height: height ?? "100%",
        }}
      >
        <LiveStreamPlayer
          streamUrl={hlsUrl}
          setDialogStyles={setDialogStyles}
          setVideoError={setVideoError}
        />
      </Box>
    </>
  );
};

const HLSStreamPlayer = memo(HLSStreamComponent);

export default HLSStreamPlayer;

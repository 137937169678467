import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import Box from "@mui/material/Box";

import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import { Button, Stack, Tooltip, useTheme } from "@mui/material";
import ConfigurationComponent from "../../../common/components/box/ConfigurationComponent";
import WarningBox from "../../../common/components/box/WarningBox";
import FullPageLoader from "../../../common/components/item/FullPageLoader";
import ItemDetailHeader from "../../../common/components/item/ItemDetailHeader";
import ItemDetailTable from "../../../common/components/item/ItemDetailTable";
import MainPaperWrapper from "../../../common/components/item/MainPaperWrapper";
import LiveViewContainer, {
  ViewMode,
  ViewModeEnum,
} from "../../../common/components/live-view/LiveViewContainer";
import BreadcrumbNavigation from "../../../common/components/tabs/BreadcrumbNavigation";
import { captureKeyframe } from "../../../common/helpers/captureKeyframe";
import { useAuthenticatedUser } from "../../../common/hooks/useAuthenticatedUser";
import { RoleEnum } from "../../../common/models/enums";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import { useSyncShadow } from "../../model-manager/hooks/useSyncShadow";
import { useUpdateService } from "../../model-manager/hooks/useUpdateService";
import { ServiceTypeEnum } from "../../model-manager/variables/modelManager";
import { useGetNodeById } from "../../nodes/hooks/useGetNodeById";
import AnnotationContainer from "../annotations/AnnotationContainer";
import { IDeviceTableRow, IModel } from "../hooks/useDeviceTableRows";
import { useGetAnnotations } from "../hooks/useGetAnnotations";
import useGetDeviceByUrl from "../hooks/useGetDeviceByUrl";
import { usePublishNode } from "../hooks/usePublishNode";
import ModelActionControls from "./ModelActionControls";

const OverviewModelPage = (): JSX.Element => {
  const theme = useTheme();
  const [deviceDetail, setDeviceDetail] = useState<IDeviceTableRow | null>(
    null
  );
  const [isKeyframeLoaded, setKeyframeLoaded] = useState(false);
  const [isModelRunning, setIsModelRunning] = useState(false);
  const [configurationParams, setConfigurationParams] = useState({
    editMode: false,
    value: "",
  });

  const { role } = useAuthenticatedUser();

  const [currentViewMode, setCurrentViewMode] = useState<ViewMode>(
    ViewModeEnum.KEYFRAME
  );

  const { fetchDevice, getServicesLoading, loading, isServiceLoaded } =
    useGetDeviceByUrl();

  const {
    fetchNode,
    data: nodeData,
    loading: getNodeLoading,
  } = useGetNodeById();
  const { updateService } = useUpdateService();
  const { syncShadowsForNode } = useSyncShadow();
  const { deviceId } = useParams();

  const { publishNode } = usePublishNode();

  let { modelType } = useParams();

  const location = useLocation();

  if (modelType === ServiceTypeEnum.LEAK) {
    modelType = ServiceTypeEnum.GAS_LEAK;
  }

  const modelDetails = useMemo((): IModel | undefined => {
    const model = deviceDetail?.models.find(
      (model): boolean =>
        model.serviceName === modelType || model.serviceName === "Leak"
    );

    if (model?.configuration) {
      setConfigurationParams({
        ...configurationParams,
        value: model.configuration,
      });

      fetchNode(model.nodeId);
    }

    setIsModelRunning(!!model?.isRunning);

    return model;
  }, [deviceDetail]);

  const { data: fetchAnnotationsData } = useGetAnnotations({
    serviceId: modelDetails?.serviceId ?? "",
    deviceId: deviceDetail?.rowId ?? "",
  });

  useEffect((): void => {
    location.state = { deviceId: deviceId };

    if (!isServiceLoaded) return;

    fetchDevice()?.then((device): void => {
      if (device) {
        setDeviceDetail(device);
      }
    });
  }, [isServiceLoaded]);

  const keyFrameOrRecentFrame = useMemo(() => {
    if (deviceDetail?.deviceData.hasPanTilt) {
      return deviceDetail?.deviceData.signedUrlRecentFrame ?? "";
    }

    return deviceDetail?.deviceData.signedUrlKeyFrame ?? "";
  }, [deviceDetail]);

  const annotationsData = fetchAnnotationsData?.getAnnotations.items;

  const showLoading =
    loading ||
    getServicesLoading ||
    getNodeLoading ||
    !annotationsData ||
    !deviceDetail ||
    !modelDetails;

  if (showLoading) {
    return <FullPageLoader />;
  }

  if (modelType === ServiceTypeEnum.GAS_LEAK) {
    modelDetails.serviceName = ServiceTypeEnum.LEAK;
  }

  const cancelConfigurationEdit = (): void => {
    setConfigurationParams({ ...configurationParams, editMode: false });
  };

  const saveConfigurationEdit = (value: string): void => {
    updateService({
      configuration: value,
      serviceId: modelDetails.serviceId,
      nodeId: modelDetails.nodeId,
    }).finally((): void => {
      syncShadowsForNode(modelDetails.nodeId);

      setConfigurationParams({ value, editMode: false });

      successNotification("Changes applied");
    });
  };

  const setIsModelRunningStatus = (value: boolean): void => {
    setIsModelRunning(value);
  };

  const handleCaptureKeyframe = (): void => {
    if (deviceDetail) {
      captureKeyframe(deviceDetail, publishNode);
    }
  };

  const hasDevicePt = deviceDetail.deviceData?.hasPanTilt;

  const tableData = [
    {
      label: "CONFIGURATION PARAMETERS",
      value: configurationParams.value,
      jsonFormat: true,
      editButton: true,
      rowStyles: { alignItems: "flex-start" },
      onEditHandler: (): void => {
        if (
          role !== RoleEnum.ROOT &&
          role !== RoleEnum.CUSTOMER_ADMIN &&
          role !== RoleEnum.PARTNER_ADMIN
        ) {
          errorNotification("You do not have permissions to access.");

          return;
        }

        setConfigurationParams({ ...configurationParams, editMode: true });
      },
    },
  ];

  const breadcrumbItems = [
    { label: "Devices", path: "/devices" },
    {
      label: deviceDetail.name,
      path: "/device/" + encodeURIComponent(deviceDetail.rowId),
    },
    { label: modelDetails.serviceName },
  ];

  const nodeDetailsRow = [
    {
      label: "NODE",
      value: nodeData?.nodeName ?? "",
    },
  ];

  const hasPanTilt = deviceDetail?.deviceData?.hasPanTilt;

  const isCaptureKeyFramesDisabled =
    (!hasDevicePt ? false : !isModelRunning) ||
    !deviceDetail?.deviceData.recentFrame ||
    !deviceDetail?.isOnline ||
    currentViewMode === ViewModeEnum.LIVE;

  let tooltipTitle =
    !isModelRunning && isCaptureKeyFramesDisabled
      ? "New key image capturing will be available when model is enabled"
      : "The model will be stopped while the key image is capturing";

  if (!deviceDetail?.isOnline) {
    tooltipTitle =
      "New key image capturing will be available when device is online";
  }

  const runningLeakModels = deviceDetail.models.filter(
    item =>
      item.isRunning &&
      (item.serviceName === ServiceTypeEnum.LEAK ||
        item.serviceName === ServiceTypeEnum.GAS_LEAK ||
        item.serviceName === ServiceTypeEnum.LIQUID_LEAK)
  );

  return (
    <>
      <BreadcrumbNavigation items={breadcrumbItems} />

      <ItemDetailHeader name={modelDetails.serviceName}>
        <ModelActionControls
          device={deviceDetail}
          isModelRunning={isModelRunning}
          setIsModelRunning={setIsModelRunningStatus}
          deviceModel={modelDetails}
          runningLeakModels={runningLeakModels}
        />
      </ItemDetailHeader>

      <MainPaperWrapper>
        <Box
          gap="1.5em"
          sx={{
            "@media (min-width: 1440px)": {
              display: "grid",
              alignItems: "flex-start",
              gridTemplateColumns: "1fr 1fr",
            },
          }}
        >
          <Box>
            {!isModelRunning && (
              <Box sx={{ marginBottom: "0.5em" }}>
                <WarningBox>
                  This model isn’t running. To receive all notifications from
                  this model, please enable it
                </WarningBox>
              </Box>
            )}

            <ItemDetailTable data={nodeDetailsRow} />

            {!configurationParams.editMode && (
              <ItemDetailTable data={tableData} />
            )}

            {configurationParams.editMode && (
              <ConfigurationComponent
                model={modelType ?? ""}
                params={configurationParams.value}
                onCancelHandler={cancelConfigurationEdit}
                onSaveHandler={saveConfigurationEdit}
              />
            )}

            {!hasDevicePt && (
              <AnnotationContainer
                deviceId={deviceDetail.rowId}
                nodeId={deviceDetail.node.id}
                annotationsData={annotationsData}
                serviceId={modelDetails.serviceId}
                serviceType={modelDetails.serviceName}
                keyFrame={keyFrameOrRecentFrame}
                isKeyframeLoaded={isKeyframeLoaded}
                setKeyframeLoaded={setKeyframeLoaded}
              />
            )}
          </Box>

          <Stack
            direction="column"
            spacing={1}
            sx={{
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            {!hasPanTilt && (
              <Tooltip title={tooltipTitle} placement="top">
                <Box sx={{ marginRight: "0.5em" }}>
                  <Button
                    disabled={isCaptureKeyFramesDisabled}
                    variant="text"
                    size="small"
                    startIcon={<PhotoCameraOutlinedIcon />}
                    color="inherit"
                    onClick={handleCaptureKeyframe}
                    sx={{
                      "&.Mui-disabled": {
                        backgroundColor: "transparent",
                        color: theme.palette.text.disabled,
                      },
                      ":hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    Capture a new key image
                  </Button>
                </Box>
              </Tooltip>
            )}

            <LiveViewContainer
              s3Key={keyFrameOrRecentFrame}
              device={deviceDetail}
              showPtButton={hasDevicePt}
              annotationsData={annotationsData}
              isKeyframeLoadedDefaultValue={isKeyframeLoaded}
              setKeyframeLoadedHandler={setKeyframeLoaded}
              defaultViewMode={
                hasDevicePt
                  ? ViewModeEnum.KEYFRAME
                  : ViewModeEnum.KEYFRAME_WITH_ANNOTATIONS
              }
              viewMode={currentViewMode}
              setCurrentViewMode={setCurrentViewMode}
            />
          </Stack>
        </Box>
      </MainPaperWrapper>
    </>
  );
};

export default OverviewModelPage;
